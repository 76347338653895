//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
export default {
  components: {
    htmlSection: () =>
      import('@/components/sections/dynamic/htmlSection.vue'),
    experienceSection: () =>
      import('@/components/sections/dynamic/experienceSection.vue'),
    heroSection: () => import('@/components/sections/dynamic/heroSection.vue'),
    experienceSectionSecond: () =>
      import('@/components/sections/dynamic/experienceSectionSecond.vue'),
    textSection: () => import('@/components/sections/dynamic/textSection.vue'),
    uploadsSection: () => import('@/components/sections/dynamic/uploadsSection.vue'),
    tableSection: () => import('@/components/sections/dynamic/tableSection.vue'),
    listSection: () => import('@/components/sections/dynamic/listSection.vue'),
    twoColumnImageTextSection: () =>
      import('@/components/sections/dynamic/twoColumnImageTextSection.vue'),
    textmediaSection: () =>
      import('@/components/sections/dynamic/textmediaSection.vue'),
    textMapSection: () =>
      import('@/components/sections/dynamic/textMapSection.vue'),
    spotheadlessHighlightsSection: () =>
      import('@/components/sections/dynamic/spotheadlessHighlightsSection.vue'),
    spotheadlessInfosSection: () =>
      import('@/components/sections/dynamic/spotheadlessInfosSection.vue'),
    toursSection: () =>
      import('@/components/sections/dynamic/toursSection.vue'),
    toursListSection: () =>
      import('@/components/sections/dynamic/toursListSection.vue'),
    toursDetail: () =>
      import('@/components/sections/dynamic/toursDetail.vue'),
    gallerySection: () =>
      import('@/components/sections/dynamic/gallerySection.vue'),
    spotdiscoverswissAllSection: () =>
      import('@/components/sections/dynamic/spotdiscoverswissAllSection.vue'),
    highlightSection: () =>
      import('@/components/sections/dynamic/highlightSection.vue'),
    highlightSecondSection: () =>
      import('@/components/sections/dynamic/highlightSecondSection.vue'),
    bookingSection: () =>
      import('@/components/sections/dynamic/bookingSection.vue'),
    faqSection: () => import('@/components/sections/dynamic/faqSection.vue'),
    spotdiscoverswissAllSliderSection: () =>
      import(
        '@/components/sections/dynamic/spotdiscoverswissAllSliderSection.vue'
        ),
    mediaundinfluencerSection: () =>
      import('@/components/sections/dynamic/mediaundinfluencerSection.vue'),
    mapSpotdiscoverswissSection: () =>
      import('@/components/sections/dynamic/mapSpotdiscoverswissSection.vue'),
    gallerySpotdiscoverswissSection: () =>
      import(
        '@/components/sections/dynamic/gallerySpotdiscoverswissSection.vue'
        ),
  },
  data() {
    return {
      metaData: null,
      page: {},
      content: [],
      isLoading: false,
      loading: true,
    }
  },
  head() {
    return {
      title:
        this.metaData && this.metaData.title
          ? `RheinWelten - ${this.metaData.title}`
          : `RheinWelten - ${this.$t('meta.title')}`,
      htmlAttrs: {
        lang:
          this.activeLang && this.activeLang.twoLetterIsoCode
            ? this.activeLang.twoLetterIsoCode
            : 'de',
      },
      meta: [
        {
          name: 'description',
          hid: 'description',
          content:
            this.metaData && this.metaData.description
              ? this.metaData.description
              : this.$t('meta.description'),
        },
        {
          name: 'language',
          content:
            this.activeLang && this.activeLang.twoLetterIsoCode
              ? this.activeLang.twoLetterIsoCode
              : 'de',
        },
        {
          name: 'image',
          hid: 'image',
          content:
            this.metaData &&
            this.metaData.ogImage &&
            this.metaData.ogImage?.cropVariants?.social?.publicUrl
              ? this.metaData.ogImage?.cropVariants?.social?.publicUrl.toString()
              : "/images/social-image.jpg",
        },
        /* Open Graph */
        {
          name: 'og:title',
          hid: 'og:title',
          content:
            this.metaData && this.metaData.ogTitle
              ? this.metaData.ogTitle
              : `RheinWelten - ${this.$t('meta.title')}`,
        },
        {
          name: 'og:site_name',
          hid: 'og:site_name',
          content: 'Rheinwelten',
        },
        {
          name: 'og:url',
          hid: 'og:url',
          content: this.redirectUrl || 'https://www.rheinwelten.com/',
        },
        {
          name: 'og:description',
          hid: 'og:description',
          content:
            this.metaData && this.metaData.ogDescription
              ? this.metaData.ogDescription
              : this.$t('meta.description'),
        },
        {
          name: 'og:type',
          hid: 'og:type',
          content: 'website',
        },
        {
          name: 'og:image',
          hid: 'og:image',
          content:
            this.metaData &&
            this.metaData.ogImage &&
            this.metaData.ogImage?.cropVariants?.social?.publicUrl
              ? this.metaData.ogImage?.cropVariants?.social?.publicUrl
              : "/images/social-image.jpg",
        },
        {
          property: 'og:image:width',
          content: '1400',
        },
        {
          property: 'og:image:height',
          content: '934',
        },
        /* Twitter */
        {
          name: 'twitter:card',
          hid: 'twitter:card',
          content:
            this.metaData && this.metaData.twitterCard
              ? this.metaData.twitterCard
              : 'summary',
        },
        { property: 'twitter:site', content: '' },
        {
          name: 'twitter:title',
          hid: 'twitter:title',
          content:
            this.metaData && this.metaData.twitterTitle
              ? this.metaData.twitterTitle
              : `RheinWelten - ${this.$t('meta.title')}`,
        },
        {
          name: 'twitter:description',
          hid: 'twitter:description',
          content:
            this.metaData && this.metaData.twitterDescription
              ? this.metaData.twitterDescription
              : this.$t('meta.description'),
        },
        {
          name: 'twitter:image',
          hid: 'twitter:image',
          content:
            this.metaData &&
            this.metaData.twitterImage &&
            this.metaData.twitterImage?.cropVariants?.social?.publicUrl
              ? this.metaData.twitterImage?.cropVariants?.social?.publicUrl
              : "/images/social-image.jpg",
        },
        {
          hid: 'robots',
          name: 'robots',
          content:
            this.metaData &&
            this.metaData.robots &&
            this.metaData.robots.noIndex
              ? 'noindex, nofollow'
              : 'index, follow',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'getFavourites'
    ]),
    redirectUrl() {
      return process.env.REDIRECT_URL
    },
    getCurrentRoute() {
      return this.$route.path.replace(/\//g, '-')
    },
  },
  async created() {
    this.getContent()
    const route = this.$route.path
    await this.$store.dispatch('getLinks', {
      url: route,
    })
  },

  async mounted(){
    if (this.$auth.loggedIn) {
      await this.$store.dispatch('loadFavourites')
    }
  },
  methods: {
    pagination(item, filter, $state) {
      this.isLoading = true
      this.getContent(
        {
          page: item.page,
          type: filter.type === 'alle' ? null : filter.type,
          category: filter?.categories?.length
            ? filter.categories.join(',')
            : [],
          experience: filter.experiences.length
            ? filter.experiences.join(',')
            : [],
          region: filter.regions.length ? filter.regions.join(',') : [],
        },
        $state
      )
    },

    filter(filter, page) {
      this.getContent({
        page,
        type: filter.type === 'alle' ? null : filter.type,
        category: filter?.categories?.length ? filter.categories.join(',') : [],
        experience: filter.experiences.length
          ? filter.experiences.join(',')
          : [],
        region: filter.regions.length ? filter.regions.join(',') : [],
      })
    },

    filterAdvance(filter) {
      this.getContent({
        page: 1,
        type: filter.type === 'alle' ? null : filter.type,
        category: filter?.categories?.length ? filter.categories.join(',') : [],
        experience: filter.experiences.length
          ? filter.experiences.join(',')
          : [],
        region: filter.regions.length ? filter.regions.join(',') : [],
      })
    },

    getContent(params, $state) {
      const route = this.$route.path
      if (!params) {
        this.$store.commit('SET_GLOBAL_LOADING', true)
      }
      this.isLoading = true
      this.$store
        .dispatch('getContentDetails', {
          url: route,
          params,
        })
        .then((res) => {
          this.page = res?.page
          this.content = res?.content?.colPos0
          this.metaData = res?.meta
          this.activeLang = res?.languages?.find((obj) => obj.active === 1)
          this.$store.commit('SET_PARTNERS', res?.page?.company[0]?.partner)
        })
        .catch((err) => {
          this.$router.push(this.$i18n.locale === 'de' ? '/error-404' : '/' + this.$i18n.locale + '/error-404')
          return err
        })
        .finally(() => {
          this.isLoading = false
          if ($state) {
            $state.loaded()
          }
        })
    },
  },
}
